import React from 'react';

import './FundSelector.scss';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import { Box } from '@mui/material';
import ProductInfo from '../ProductInfo/ProductInfo';
import { IndexFund } from '../../../../store_deprecated/types/indexFundTypes';
import COLORS from '../../../../constants/colors';
import DocButton from '../DocButton/DocButton';
import { ProductFile } from '../../../../types/product';

interface FundProps {
  indexFund: IndexFund;
  checked: boolean;
  onClick: (indexFund: IndexFund) => void;
}

const Fund: React.FC<FundProps> = ({ indexFund, onClick, checked }) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  return (
    <div className={`fund-item ${checked && 'fund-item-checked'}`} onClick={() => onClick(indexFund)}>
      <Checkbox
        checked={checked}
        sx={{
          color: '#e4e7ec',
          '&.MuiCheckbox-root': {
            marginRight: '16px',
            height: 'fit-content',
            alignSelf: 'center',
          },
        }}
        checkedIcon={<CheckBoxRoundedIcon sx={{ color: COLORS.X_DARK_BLUE }} />}
      />
      <div className="fund-info">
        <Typography className="asset" sx={{ fontSize: 10, color: COLORS.DARK_GREY }}>
          {t('products.assetClass')}
        </Typography>
        <Typography sx={{ fontSize: 16, color: COLORS.BLACK }} fontWeight="bold">
          {isEnglish ? indexFund.nameEn : indexFund.nameAr}
        </Typography>
        <Box sx={{ width: '100%', height: '4px', display: 'flex', my: '8px' }}>
          <Box
            sx={{
              width: '75%',
              backgroundColor: COLORS.DARK_BLUE,
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
            }}
          />
          <Box sx={{ width: '20%', backgroundColor: COLORS.CYAN }} />
          <Box
            sx={{
              width: '10%',
              backgroundColor: COLORS.LIGHT_GREEN,
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px',
            }}
          />
        </Box>
        <ProductInfo
          strategy={indexFund.strategy}
          manager={isEnglish ? indexFund.manager.nameEn : indexFund.manager.nameAr}
          expReturn={indexFund.expectedReturn}
          showBullet
        />
        <div className="doc-buttons">
          {(indexFund.attachments as ProductFile[]).slice(0, 1).map((file: ProductFile) => (
            <DocButton key={file.id} document={file} className="fact-doc" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Fund;
