import { Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { type } from 'os';
import COLORS from '../../../constants/colors';
import { getUserIndexFundsSelector } from '../../../store_deprecated/selectors/indexFundSelectors';
import {
  getPortfolioStatisticsSelector,
  getSubscribedPortfolioSelector,
} from '../../../store_deprecated/selectors/portfolioSelectors';
import { madkhoulBankInfoSelector } from '../../../store_deprecated/selectors/walletSelectors';
import {
  SubscribedPortfolio,
  SubscribedPortfolioStatus,
  SubscriptionStatus,
} from '../../../store_deprecated/types/portfolioTypes';
import { formatNumber, round2Format } from '../../../utils/number';
import { ProductPositions } from '../../../utils/ProductPositions';
import convertToInternationalCurrencySystem from '../../../utils/conversion';
import './widgets.scss';
import { getAlpacaDividendsSelector } from '../../../store_deprecated/selectors/alpacaSelectors';

type WidgetsValues = {
  totalCostBase?: string;
  totalMarketValue?: string;
  totalSarReturn?: string;
  totalReturn?: string;
  totalAmount?: string;
  overallTotalCostBase?: string;
  overallTotalMarketValue?: string;
  overallTotalSarReturn?: string;
  overallTotalReturn?: string;
  overallTotalAmount?: string;
};

type OverallTotal = {
  overallTotalCostBase?: number;
  overallTotalMarketValue?: number;
  overallTotalSarReturn?: number;
  overallTotalReturn?: number;
  overallTotalAmount?: number;
};

type WidgetItem = {
  title: string;
  value: string;
  tooltip: string | number;
  currency: string;
  color: string;
  width?: string;
  valueKey: keyof WidgetsValues;
};

const standardWidthInPercentage = 18;

const dashboardWidgets: WidgetItem[] = [
  {
    title: 'totalCostBaseWidgetTitle',
    value: '',
    tooltip: '',
    currency: 'SAR',
    color: COLORS.MAIN_DARK,
    valueKey: 'totalCostBase',
    width: `${standardWidthInPercentage}%`,
  },
  {
    title: 'totalMarketValueWidgetTitle',
    value: '',
    tooltip: '',
    currency: 'SAR',
    color: COLORS.SECONDARY_GREEN,
    valueKey: 'totalMarketValue',
    width: `${standardWidthInPercentage}%`,
  },
  {
    title: 'totalSarReturnWidgetTitle',
    value: '',
    tooltip: '',
    currency: 'SAR',
    color: COLORS.MAIN_DARK,
    valueKey: 'totalSarReturn',
    width: `${standardWidthInPercentage}%`,
  },
  {
    title: 'totalReturnWidgetTitle',
    value: '',
    tooltip: '',
    currency: '',
    color: COLORS.PRIMARY_BLUE,
    valueKey: 'totalReturn',
    width: `${standardWidthInPercentage}%`,
  },
  // {
  //   title: 'totalAmountWidgetTitle',
  //   value: '',
  //   tooltip: '',
  //   currency: 'SAR',
  //   color: COLORS.ORANGE,
  //   valueKey: 'totalAmount',
  //   width: `${100 - 4 * standardWidthInPercentage}%`,
  // },
];

interface WidgetsProps {
  subscribedProducts: any;
}

const Widgets: React.FC<WidgetsProps> = ({ subscribedProducts }) => {
  const { t, i18n } = useTranslation();
  const percentFormatter = Intl.NumberFormat(i18n.language === 'en' ? 'en' : 'ar-SA', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [widgets, setWidgets] = useState<WidgetItem[]>(dashboardWidgets);
  const { latest: portfolioStatisticsData } = useSelector(getPortfolioStatisticsSelector);
  const bankInfo = useSelector(madkhoulBankInfoSelector);
  const { dividends } = useSelector(getAlpacaDividendsSelector);

  useEffect(() => {
    let overallTotal: OverallTotal = {
      overallTotalCostBase: 0,
      overallTotalMarketValue: 0,
      overallTotalSarReturn: 0,
      overallTotalReturn: 0,
      overallTotalAmount: 0,
    };

    const subscribedPortfolios = subscribedProducts.filter(
      (sp: any) => sp.status.name === SubscribedPortfolioStatus.ACCEPTED,
    );

    subscribedPortfolios.forEach((subscribedPortfolio: any) => {
      const userIndexFunds = subscribedPortfolio.subscribedPortfolioAssociatedIndexFund;

      const cashValue = subscribedPortfolio.cacheAllocationValue;

      const portfolioPositions = new ProductPositions({
        realTime: true,
        portfolio: {
          ...subscribedPortfolio,
          associatedIndexFunds: subscribedPortfolio.subscribedPortfolioAssociatedIndexFund,
        },
        userIndexFunds,
        // @ts-ignore
        portfolioStatisticsData,
      });

      const values = portfolioPositions.calculateTotalValues(portfolioPositions.calculatePositions());

      const totalMarketValue = values.totalMarketValue + cashValue + dividends;
      const netDeposit = bankInfo?.userBankDetails?.amount || 0;

      overallTotal = {
        overallTotalCostBase: (overallTotal.overallTotalCostBase || 0) + (values.totalCostBase + cashValue),
        overallTotalMarketValue: (overallTotal.overallTotalMarketValue || 0) + totalMarketValue,
        overallTotalSarReturn: (overallTotal.overallTotalSarReturn || 0) + values.totalSarReturn,
        overallTotalReturn: (overallTotal.overallTotalReturn || 0) + (values.totalReturn || 0),
        overallTotalAmount: (overallTotal.overallTotalAmount || 0) + (totalMarketValue + netDeposit),
      };

      return overallTotal;
    });
    const widgetsValues = {
      totalCostBase: round2Format(overallTotal.overallTotalCostBase || 0, i18n.language),
      totalMarketValue: round2Format(overallTotal.overallTotalMarketValue || 0, i18n.language),
      totalSarReturn: round2Format(overallTotal.overallTotalSarReturn || 0, i18n.language),
      // handle subscribedProducts.length = 0
      totalReturn: subscribedPortfolios.length
        ? percentFormatter
            .format((overallTotal.overallTotalReturn || 0) / subscribedPortfolios.length / 100)
            .replace('%', '')
        : '_',
      totalAmount: round2Format(overallTotal.overallTotalAmount || 0, i18n.language),
    };

    setWidgets(
      widgets.map((widget) => ({
        ...widget,
        // @ts-ignore
        value: widgetsValues[widget.valueKey],
        // @ts-ignore
        tooltip: widgetsValues[widget.valueKey],
      })),
    );

    // return {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankInfo, portfolioStatisticsData, subscribedProducts, dividends, i18n.language]);

  return (
    <Grid container rowSpacing={0} justifyContent="space-between" className="widgets-list" spacing={{ xs: 2, md: 3 }}>
      {widgets.map((w, i) => (
        <Tooltip title={w.tooltip} followCursor key={w.title}>
          <Grid item xs={6} sm={3} md={2}>
            <Grid
              container
              flexDirection="column"
              component={Paper}
              elevation={0}
              sx={{
                p: {
                  xs: '15px 15px',
                  sm: '32px 24px',
                },
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
                borderRadius: '12px',
              }}
            >
              <Grid item>
                <Typography
                  sx={{
                    color: COLORS.BLACK,
                    fontWeight: {
                      xs: 600,
                      ms: 500,
                    },
                    fontSize: {
                      xs: '12px',
                      md: '14px',
                    },
                    lineHeight: '24px',
                  }}
                  variant="body2"
                >
                  {t(w.title)}
                </Typography>
              </Grid>
              <Grid item flexDirection="row" display="flex" justifyContent="space-between" alignItems="baseline">
                <Typography
                  sx={{
                    color: w.color,
                    fontWeight: 700,
                    fontSize: { xs: '1.2rem', md: '24px' },
                    lineHeight: '32px',
                  }}
                  variant="h5"
                >
                  {w.value}
                </Typography>
                <Typography
                  sx={{
                    color: COLORS.BLACK,
                    fontWeight: 500,
                    fontSize: {
                      xs: '0.875rem',
                      md: '12px',
                    },
                    lineHeight: '24px',
                    minHeight: '24px',
                  }}
                  variant="body2"
                >
                  {w.currency ? t(`currency.${w.currency}`) : '%'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Tooltip>
      ))}
    </Grid>
  );
};

export default Widgets;
