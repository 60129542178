import React from 'react';
import { InlineWidget } from 'react-calendly';
import Box from '@mui/material/Box';

const AskForAdvice: React.FC = () => (
  <Box>
    <InlineWidget url="https://calendly.com/hq-madkhol" />
  </Box>
);

export default AskForAdvice;
