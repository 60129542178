import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import COLORS from '../../../constants/colors';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { madkhoulBankInfoSelector } from '../../../store_deprecated/selectors/walletSelectors';
import MButton from '../../../shared/MButton';
import FundAmountField from '../Education/FundAmountField';
import PaymentSuccessfulScreen from '../../../shared/PaymentSuccessfulScreen';
import PaymentFailureScreen from '../../../shared/PaymentFailureScreen';
// import PaymentHyperPayProcessingScreen from '../../../shared/PaymentHyperPayProcessingScreen';
import { ar2En, formatNumber, round2Format } from '../../../utils/number';
import { formatValue } from '../../../helpers/string';

interface DepositDialogProps {
  open: boolean;
  isLoading?: boolean;
  error?: string;
  showSuccessfulScreen?: boolean;
  showFailureScreen?: boolean;
  // showHyperPayProcessingScreen?: boolean;
  isApplePayAvailable: boolean;
  isMasterCardAvailable: boolean;
  // hyperPayCheckoutId?: string;
  closeDialog?: () => void;
  onSuccessButtonClick?: () => void;
  onClose: (provider: 'card' | 'apple' | 'appleRecurring', fundAmount?: number) => void;
}

const minAmount = 1;

export default function DepositDialog(props: DepositDialogProps) {
  const {
    open,
    isLoading,
    error,
    showSuccessfulScreen = false,
    showFailureScreen = false,
    // showHyperPayProcessingScreen = false,
    // hyperPayCheckoutId = '',
    closeDialog = () => {},
    onSuccessButtonClick = () => {},
    onClose,
  } = props;
  const { t, i18n } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const [amount, setAmount] = useState('');
  const [isRecurring, setIsRecurring] = useState(false);

  const { user } = useSelector(authSelector);

  // TODO: remove  after testing
  let displayRecurringButton = false;
  if (user?.id === 3941 || user?.id === 92) {
    displayRecurringButton = true;
  }
  const isAmountError = Boolean(amount) && +amount < minAmount;
  const isArabic = i18n.language === 'ar';

  const bankItem = useSelector(madkhoulBankInfoSelector);

  const handleConfirm = (provider: 'card' | 'apple' | 'appleRecurring', fundAmount: number) => {
    if (isLoading || !amount) {
      return;
    }

    setAmount('');
    // setIsRecurring(provider === 'hyperPayRecurring');

    return !showSuccessfulScreen ? onClose(provider, fundAmount) : closeDialog();
  };

  window.handleAppleConfirm = () => handleConfirm('apple', +amount);
  window.handleAppleRecurringConfirm = () => handleConfirm('appleRecurring', +amount);

  useEffect(() => () => setAmount(''), [open]);

  const parseValue = (v: string | number) =>
    `${v}`
      // eslint-disable-next-line no-useless-escape
      .replace(/([\.\-\,])(?=.*\1)/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/[^.\-\d]/g, '');

  const handleFieldChange = (event: any) => {
    const initialValue = event.target.value;

    const newValue: string = parseValue(ar2En(initialValue));
    const newValueNumber = +newValue;

    // setValue(newValue);

    if (Number.isNaN(newValueNumber) || newValue.endsWith('.') || !newValue) {
      event.target.value = newValue;

      setAmount(event.target.value);

      return;
    }

    event.target.value = `${newValueNumber}`;

    setAmount(event.target.value);
    // Temporary solution to pass amount between screens
    sessionStorage.setItem('lastPaymentAmount', `${newValueNumber}`);
  };

  const renderContent = () => (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" rowSpacing="48px">
      <Grid item textAlign="right" alignSelf="end">
        <IconButton onClick={closeDialog}>
          <CloseIcon sx={{ fontSize: '12px', color: COLORS.MAIN_DARK }} />
        </IconButton>
      </Grid>
      <Grid item sx={{ paddingTop: { xs: '24px !important', md: '48px !important' } }}>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 500,
            fontSize: { xs: '24px', md: '44px' },
            lineHeight: { xs: '32px', md: '48px' },
            color: COLORS.MAIN_DARK,
          }}
        >
          {t('depositDialog.title')}
        </Typography>
      </Grid>
      <Grid item container flexDirection="row" justifyContent="space-around" alignItems="center">
        <Typography
          variant="bodyMedium"
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('depositDialog.currentFund')}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '28px',
            color: COLORS.PRIMARY_BLUE,
          }}
        >
          {t('depositDialog.nPerMonth', {
            val: round2Format(Number(bankItem?.userBankDetails?.amount) || 0, i18n.language),
          })}
        </Typography>
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <FundAmountField
          isArabic={isArabic}
          value={formatValue(isArabic, amount.toString())}
          label="depositDialog.foundAmountFieldLabel"
          placeholder="depositDialog.foundAmountFieldPlaceholder"
          handleChange={handleFieldChange}
        />
        <Typography
          variant="bodySmall"
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('depositDialog.note', { val: '1,000' })}
        </Typography>
      </Grid>
      {(isLoading || !!error || isAmountError) && (
        <Grid item>
          {isLoading && <CircularProgress />}
          {(!!error || isAmountError) && (
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: { xs: '13px', md: '18px' },
                lineHeight: '28px',
                color: COLORS.RED,
              }}
            >
              {error ||
                tApiError('AMOUNT_LESS_THAN_MINIMUM_WITHDRAW_AMOUNT', {
                  val: formatNumber(minAmount, i18n.language, 2),
                })}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item container rowSpacing="24px">
        <Grid item width="100%" id="ConfirmContainer">
          {props.isMasterCardAvailable && (
            <Grid item container flexDirection="column">
              <MButton
                id="PayWithMadaCardButton"
                variant="contained"
                onClick={() => handleConfirm('card', +amount)}
                disabled={!amount || isAmountError || isLoading}
                fullWidth
                buttonProps={{
                  sx: {
                    backgroundColor: COLORS.X_DARK_BLUE,
                    backgroundImage: 'none',
                  },
                }}
              >
                {t('depositDialog.payWithMadaCard')}
              </MButton>
            </Grid>
          )}
          {/* <Grid item sx={{ mt: '24px' }}>
            <MButton
              id="PayWithHyperPayMadaButton"
              variant="contained"
              onClick={() => handleConfirm('hyperPayMada', +amount)}
              disabled={!amount || isAmountError || isLoading}
              fullWidth
              buttonProps={{
                sx: {
                  backgroundColor: COLORS.X_DARK_BLUE,
                  backgroundImage: 'none',
                },
              }}
            >
              {t('depositDialog.payWithHyperPayMadaCard')}
            </MButton>
          </Grid> */}
          {/* <Grid item sx={{ mt: '24px' }}>
            <MButton
              id="PayWithHyperPayVisaOrMastercardButton"
              variant="contained"
              onClick={() => handleConfirm('hyperPayVisaOrMaster', +amount)}
              disabled={!amount || isAmountError || isLoading}
              fullWidth
              buttonProps={{
                sx: {
                  backgroundColor: COLORS.X_DARK_BLUE,
                  backgroundImage: 'none',
                },
              }}
            >
              {t('depositDialog.payWithHyperPayVisaOrMastercard')}
            </MButton>
          </Grid> */}
          {/* <Grid item sx={{ mt: '24px' }}>
            <MButton
              id="RecurringPayWithHyperPayMadaButton"
              variant="contained"
              onClick={() => handleConfirm('hyperPayRecurring', +amount)}
              disabled={!amount || isAmountError || isLoading}
              fullWidth
              buttonProps={{
                sx: {
                  backgroundColor: COLORS.X_DARK_BLUE,
                  backgroundImage: 'none',
                },
              }}
            >
              {t('depositDialog.recurringPayWithHyperPayMadaCard')}
            </MButton>
          </Grid> */}
          {props.isApplePayAvailable && (
            <Grid item sx={{ mt: '24px' }}>
              {/* @ts-ignore */}
              <apple-pay-button buttonstyle="black" type="plain" locale="en-US" onclick="handleAppleConfirm()" />
            </Grid>
          )}
          {/* {props.isApplePayAvailable && displayRecurringButton && (
            <Grid item sx={{ mt: '24px' }}>
              <apple-pay-button
                buttonstyle="black"
                type="subscribe"
                locale="en-US"
                onclick="handleAppleRecurringConfirm()"
              />
            </Grid>
          )} */}
        </Grid>
        <Grid item width="100%">
          <MButton variant="outlined" onClick={() => closeDialog()} disabled={isLoading} fullWidth>
            {t('cancel')}
          </MButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={open} onClose={closeDialog} disableScrollLock>
      <Box sx={{ padding: '32px 24px' }}>
        {!showSuccessfulScreen && !showFailureScreen && renderContent()}
        {showSuccessfulScreen && <PaymentSuccessfulScreen onSuccessButtonClick={onSuccessButtonClick} />}
        {showFailureScreen && <PaymentFailureScreen onSuccessButtonClick={onSuccessButtonClick} />}
      </Box>
    </Dialog>
  );
}
