import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { Buffer } from 'buffer';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { authSelector } from '../../redux/features/auth/auth.selectors';
import { useLoginMutation, useSendOTPMutation, useVerifyOTPMutation } from '../../redux/features/auth/auth.apiSlice';
import { setCredentials } from '../../redux/features/auth/auth.slice';
import { LoginRequest } from '../../types/auth';
import { validate } from '../../utils/validate';
import COLORS from '../../constants/colors';
import OTPInput from '../../components/User/Login/OTPInput';
import CountDown from '../../components/User/Login/CountDown';
import MobileField from '../../components/User/Signup/MobileField';
import PasswordField from '../../components/User/Signup/PasswordField';
import EmailField from '../../components/User/Signup/EmailField';
import MButton from '../../shared/MButton';
import { saveUserPreference } from '../../redux/modules/userPreference/userPreference.actions';
import { UserPreferenceTypeEnum } from '../../types/userPreference';
import { resetReduxStore, useAppDispatch } from '../../redux/store';
import SOCIAL_MEDIA from '../../assets/social-media.json';
import { locations } from '../../routes/locations';

const Loader = React.lazy(() => import('../../shared/Loader'));

const OTP_INPUT_LENGTH = 4;

const LoginButton = () => {
  const { t, i18n } = useTranslation('auth', { useSuspense: true });
  return (
    <MButton
      fullWidth
      variant="contained"
      buttonProps={{
        type: 'submit',
        sx: {
          mt: { xs: 0, md: 3 },
          mb: 6,
          width: { xs: '100%', md: '100%' },
          height: { xs: '50px', md: 60 },
          backgroundColor: COLORS?.X_DARK_BLUE,
        },
      }}
      typographyProps={{
        variant: i18n.language === 'en' ? 'h6' : 'cairoH4',
        sx: { textTransform: 'capitalize', color: 'white' },
      }}
    >
      {t('loginTitle')}
    </MButton>
  );
};

type LoginType = 'mobile' | 'email';

const Login = () => {
  const dispatch_ = useAppDispatch();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('auth', { useSuspense: true });
  const { t: tApiError } = useTranslation('apiError');
  const isEnglish = i18n.language === 'en';

  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const [sendOTP, { isLoading: isSendOTPLoading }] = useSendOTPMutation();
  const [verifyOTP, { isLoading: isVerifyOTPLoading }] = useVerifyOTPMutation();

  const navigate = useNavigate();
  const errRef = useRef<any>();
  const [loginType, setLoginType] = useState<LoginType>((localStorage.getItem('loginType') as LoginType) || 'mobile');
  // TODO: Rework to formik
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [pwd, setPwd] = useState('');
  const [loginPassed, setLoginPassed] = useState(false);
  const [otpPassed, setOtpPassed] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [errPwdMsg, setErrPwdMsg] = useState('');
  const [apiErrData, setApiErrData] = useState<[string, object]>(['', {}]);
  const [otpSendTime, setOtpSendTime] = useState(0);
  const [showLoader, setShowLoader] = useState(isLoginLoading || isSendOTPLoading || isVerifyOTPLoading);
  const { isLoggedIn } = useSelector(authSelector);
  const languages = { ar: 'العربية', en: 'English' };
  const language = i18n.language === 'ar' ? languages.en : languages.ar;
  const isMobile = loginType === 'mobile';
  const [otpLabel, setOtpLabel] = useState(false);
  const goTo = (url: string) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    setErrMsg('');
    setErrPwdMsg('');
  }, [mobile, email, otp, pwd]);

  const resetError = () => {
    setErrMsg('');
    setErrPwdMsg('');
    setApiErrData(['', {}]);
  };
  const handleSubmitLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const value = isMobile ? mobile : email;

    const errors: any = validate({ type: 'password', value: pwd });
    if (value?.trim() === '') {
      setErrMsg('missingField');
    } else if (pwd?.trim() === '') {
      setErrPwdMsg('missingField');
    } else if (errors.length) {
      setErrPwdMsg('wrongPassword');
    } else if (validate({ type: loginType, value })) {
      try {
        const payload: LoginRequest = {
          password: Buffer.from(pwd, 'utf8').toString('base64'),
          ...(isMobile ? { mobileNumber: mobile } : { email }),
        } as LoginRequest;

        setShowLoader(true);
        setOtpSendTime(Date.now());

        await login(payload).then((e: any) => {
          if (e?.data?.email) {
            setOtpLabel(true);
          } else {
            setOtpLabel(false);
          }
          dispatch(setCredentials(e?.data));
        });

        setShowLoader(false);
        setLoginPassed(true);
        resetError();
      } catch (error: any) {
        if (i18n.exists(`apiError:${error?.data?.message}`)) {
          setApiErrData([error.data.message, { ...(error.data?.data || {}) }]);
        } else if (i18n.exists(`apiError:${error?.data?.data}`)) {
          setApiErrData([error.data.data, { ...(error.data?.data || {}) }]);
        }
        setShowLoader(false);
        setErrMsg(isMobile ? 'loginMobileNumberError' : 'loginEmailNumberError');
      }
    } else {
      setShowLoader(false);
      setErrMsg('invalidFormat');
    }
  };

  const handleChangeLanguage = () => {
    localStorage.setItem('i18nextLng', i18n.language === 'en' ? 'ar' : 'en');
    i18n.changeLanguage(i18n.language === 'en' ? 'ar' : 'en');

    if (isLoggedIn) {
      dispatch_(
        saveUserPreference({
          type: UserPreferenceTypeEnum.PERF_LANG,
          value: i18n.language,
        }),
      );
    }
  };

  const resendOTP = async () => {
    setOtp('');
    resetError();
    setOtpSendTime(Date.now());
    await sendOTP(isMobile ? { mobileNumber: mobile } : { email }).unwrap();
  };

  const handleSubmitOtp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (otp.length !== OTP_INPUT_LENGTH && otpSendTime) {
      return setErrMsg('missingFields');
    }
    if (!otpSendTime) {
      return setErrMsg('resendOTP');
    }

    setErrMsg('');
    setErrPwdMsg('');
    setShowLoader(true);

    try {
      const otpVerifyResponse = await verifyOTP(
        isMobile ? { mobileNumber: mobile, otpCode: otp } : { email, otpCode: otp },
      ).unwrap();

      const userToken = otpVerifyResponse?.otpToken;

      setOtpPassed(true);
      resetError();

      const payload: LoginRequest = {
        password: Buffer.from(pwd, 'utf8').toString('base64'),
        userToken,
        ...(isMobile ? { mobileNumber: mobile } : { email }),
      } as LoginRequest;

      const res = await login(payload).unwrap();

      dispatch(setCredentials(res));

      const lastPage = sessionStorage.getItem('lastPage');
      const nextPage = lastPage && lastPage !== '/login' ? lastPage : '/';

      sessionStorage.removeItem('lastPage');
      setShowLoader(false);

      navigate(nextPage);
    } catch (error: any) {
      if (i18n.exists(`apiError:${error?.data?.data}`)) {
        setApiErrData([error?.data?.data, { ...(error?.data?.data || {}) }]);
      } else if (i18n.exists(`apiError:${error?.data?.message}`)) {
        setApiErrData([error?.data?.message, { ...(error?.data?.data || {}) }]);
      }
      setOtpPassed(false);
      setOtp('');

      if (error?.data?.message !== 'COULD_NOT_VERIFY_OTP') {
        setMobile('');
        setEmail('');
        setPwd('');
        setLoginPassed(false);
      }
    }

    setShowLoader(false);
  };

  const getLoginValidationError = () => {
    let validationStatus = '';

    const value = isMobile ? mobile : email;

    if (!value) {
      validationStatus = 'requiredField';
    } else if (!validate({ type: loginType, value })) {
      validationStatus = 'invalidFormat';
    }

    return validationStatus;
  };

  const handleLoginValidation = () => {
    setErrMsg(getLoginValidationError());
  };

  const toggleLoginType = () => {
    const nextLoginType = loginType === 'mobile' ? 'email' : 'mobile';

    setLoginType(nextLoginType);
    resetError();
    localStorage.setItem('loginType', nextLoginType);
  };

  const renderMobileField = () => (
    <MobileField
      isArabic={!isEnglish}
      mobileNumber={mobile}
      mobileNumberInvalidMessage={errMsg}
      handleChangeMobile={(value) => {
        setMobile(value);
      }}
      handleMobileValidation={handleLoginValidation}
    />
  );

  const renderEmailField = () => (
    <EmailField
      isArabic={!isEnglish}
      email={email}
      emailInvalidMessage={errMsg}
      handleChangeEmail={(event) => setEmail(event.target.value)}
      handleEmailValidation={handleLoginValidation}
      showLabel
    />
  );

  const renderLoginView = () => (
    <Box component="form" onSubmit={handleSubmitLogin} noValidate sx={{ mt: 1, width: '100%' }}>
      <Box>
        {loginType === 'mobile' ? renderMobileField() : renderEmailField()}
        <Box sx={{ mt: 6 }} />
        <PasswordField
          isArabic={!isEnglish}
          password={pwd}
          handleChangePassword={(e) => setPwd(e.target.value.replace(/\s/g, ''))}
          handleMouseDownPassword={(event: any) => {
            event.preventDefault();
          }}
        />
        <Typography
          component="h5"
          variant="body2"
          ref={errRef}
          className={errPwdMsg ? 'errmsg' : 'offscreen'}
          aria-live="assertive"
          color="error"
        >
          {apiErrData[0] ? tApiError(...apiErrData) : t(errPwdMsg)}
        </Typography>
        <Link component={ReactLink} to={locations.resetPassword()} style={{ textDecoration: 'none' }}>
          <Typography variant="bodySmall" sx={{ color: COLORS.LIGHT_GRAY }}>
            {t('forgotPassword')}
          </Typography>
        </Link>
      </Box>
      <LoginButton />
    </Box>
  );

  const renderOtpView = () => (
    <Box component="form" onSubmit={handleSubmitOtp} noValidate sx={{ mt: 1, width: '100%' }}>
      <Typography variant="bodyMedium" sx={{ color: COLORS.MAIN_DARK }} fontWeight={500}>
        {otpLabel ? t('emailCode') : t('mobileCode')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '56px',
          mt: 4,
          mb: 6,
          '@media (max-width:1050px)': {
            mt: '10px',
          },
        }}
      >
        <OTPInput
          autoFocus
          isArabic={!isEnglish}
          length={OTP_INPUT_LENGTH}
          clearOTP={otpSendTime !== 0}
          className="otpContainer"
          inputClassName="otpInput singleInput"
          onChangeOTP={(v) => setOtp(v)}
          style={{ height: '100%' }}
          // isNumberInput // adding isNumberInput make input field doesn't accept arabic numbers
          inputStyle={{
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: COLORS.XLIGHT_GRAY,
            borderRadius: '8px',
            height: '60px',
            width: '60px',
          }}
        />
      </Box>
      <Typography
        component="h5"
        variant="body2"
        ref={errRef}
        className={errMsg ? 'errmsg' : 'offscreen'}
        aria-live="assertive"
        color="error"
      >
        {apiErrData[0] ? tApiError(...apiErrData) : t(errMsg)}
      </Typography>
      <LoginButton />
      <CountDown otpSendTime={otpSendTime} setOtpSendTime={setOtpSendTime} resendOTP={resendOTP} />
    </Box>
  );

  useEffect(() => {
    const parsedNumber = parsePhoneNumber(email);

    if (!isMobile && isPossiblePhoneNumber(email) && parsedNumber?.number) {
      setMobile(parsedNumber.number);
      toggleLoginType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobile, email]);

  useEffect(() => {
    dispatch_(resetReduxStore());
  }, []);

  if (showLoader) {
    return <Loader />;
  }

  return (
    <Box>
      <Box
        sx={{
          py: '20px',
          px: '24px',
          display: 'flex',
          '@media (max-width: 1050px)': {
            flexDirection: 'column',
            px: '20px',
            pb: '70px',
            gap: '0px',
          },
        }}
      >
        <Box
          sx={{
            width: '48%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '100px',
            backgroundColor: '#F7F9FA',
            borderRadius: '16px',
            py: '40px',
            '@media (max-width: 1050px)': {
              backgroundColor: 'transparent',
              width: '100%',
              py: '5px',
            },
          }}
        >
          <Box
            sx={{
              '@media (max-width: 1050px)': {
                display: 'none',
              },
            }}
          >
            <img
              src={isEnglish ? '/images/logo-with-text-en.png' : '/images/logo-with-text-ar.png'}
              alt="madkhol logo"
              width={280}
            />
          </Box>
          <Box
            sx={{
              '@media (max-width: 1050px)': {
                width: '70%',
              },
              '@media (max-width: 760px)': {
                width: '100%',
              },
            }}
          >
            <img src="/images/madkhol-login.svg" alt="" width="100%" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              '@media (max-width: 1050px)': {
                display: 'none',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              {SOCIAL_MEDIA.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <img
                    key={item.id}
                    src={`/images/${item.name}.svg`}
                    alt={`${item.name} logo`}
                    onClick={() => goTo(item.url)}
                  />
                </Box>
              ))}
            </Box>
            <Box>
              <Typography
                component="span"
                sx={{
                  mt: '5px',
                  color: COLORS?.BLACK,
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              >
                {isEnglish ? '\u00a9' : ''}
                {`${new Date().getFullYear()} ${!isEnglish ? '\u00a9' : ''} ${t('reservedRight')}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: '52%',
            '@media (max-width: 1050px)': {
              width: '100%',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '160px',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                right: '-23px',
                top: '15px',
                zIndex: -1,
                '@media (max-width: 1050px)': {
                  display: 'none',
                },
              }}
            >
              <img src={isEnglish ? '/images/login-layer.svg' : '/images/layer-arabic.svg'} alt="" />
            </Box>
            <Box
              sx={{
                pl: '60px',
                '@media (max-width: 1050px)': {
                  display: 'none',
                },
              }}
            >
              <Button
                onClick={handleChangeLanguage}
                sx={{
                  '&:hover': {
                    backgroundColor: COLORS.TRANSPARENT,
                  },
                }}
              >
                <Typography
                  variant={isEnglish ? 'cairoM' : 'bodySmall'}
                  color="black"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {language}
                </Typography>
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box>
                <Typography
                  variant={isEnglish ? 'smallDisplayH' : 'cairoHeader1B'}
                  component="span"
                  sx={{
                    color: COLORS?.BLACK,
                    fontSize: '24px',
                    fontWeight: 600,
                    mb: '24px',
                  }}
                >
                  {t('login')}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '500px',
                  '@media (max-width: 1050px)': {
                    width: '72%',
                  },
                  '@media (max-width: 760px)': {
                    width: '100%',
                  },
                }}
              >
                {!loginPassed && !otpPassed && renderLoginView()}
                {loginPassed && !otpPassed && renderOtpView()}
              </Box>
              <Box>
                <Typography
                  variant="bodySmall"
                  sx={{
                    color: COLORS?.LIGHT_GRAY,
                  }}
                >
                  {t('noAccount')}
                  <Link component={ReactLink} to="/sign-up" underline="none" sx={{ mx: '5px' }}>
                    <Typography
                      variant={isEnglish ? 'bodyMediumB' : 'cairoBold'}
                      component="span"
                      sx={{
                        color: COLORS?.X_DARK_BLUE,
                      }}
                    >
                      {t('signUp')}
                    </Typography>
                  </Link>
                </Typography>
              </Box>

              {!loginPassed && (
                <Box
                  sx={{
                    mt: '24px',
                    textAlign: 'center',
                    '@media (max-width: 1050px)': {
                      mt: '5px',
                    },
                  }}
                >
                  <Grid item sx={{ mb: { xs: 0, md: 6 } }}>
                    <Typography variant="bodySmall">
                      <Link onClick={toggleLoginType} underline="none" sx={{ mx: '5px', cursor: 'pointer' }}>
                        <Typography
                          variant={isEnglish ? 'bodyMediumB' : 'cairoBold'}
                          component="span"
                          sx={{
                            color: COLORS?.X_DARK_BLUE,
                          }}
                        >
                          {t(loginType === 'mobile' ? 'loginWithEmail' : 'loginWithMobile')}
                        </Typography>
                      </Link>
                    </Typography>
                  </Grid>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <CenterContent>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            bottom: '1.7vh',
            display: { md: 'block', sm: 'none', xs: 'none' },
          }}
        >
          <img
            style={{ width: '100%' }}
            src={isEnglish ? '/images/logo-left-cut.svg' : '/images/logo-right-cut.svg'}
            alt=""
          />
        </Box>
        <Box
          component="main"
          sx={{
            marginX: '72px',
            width: { xs: '95%', md: '100%' },
            margin: { xs: 0, sm: '0 72px' },
            py: '5%',
          }}
        >
          <Grid
            container
            flexDirection="row"
            justifyContent={{ xs: 'center', md: 'space-between' }}
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                '& img': {
                  marginLeft: 'auto',
                  marginRight: { xs: 'auto', md: 'unset' },
                },
              }}
            >
              <img src="/images/auth/login-illustration.png" alt="" style={{ width: '90%', display: 'block' }} />
            </Grid>
            <Grid
              item
              xs={11}
              md={5}
              component={Paper}
              elevation={0}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                padding: { xs: '24px', md: '48px' },
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05);',
                borderRadius: '12px',
              }}
            >
              <Typography
                component="h1"
                variant={isEnglish ? 'smallDisplayH' : 'cairoHeader1B'}
                sx={{ mb: 6, textTransform: 'capitalize' }}
              >
                {t('login')}
              </Typography>
              <Typography variant={isEnglish ? 'bodyLarge' : 'cairoBody'} color={COLORS.LIGHT_GRAY} sx={{ mb: 8 }}>
                **DESCRIPTION**
              </Typography>
              {!loginPassed && !otpPassed && renderLoginView()}
              {loginPassed && !otpPassed && renderOtpView()}
              <Grid container flexDirection="column" justifyContent="center" alignItems="center">
                {!loginPassed && (
                  <Grid item sx={{ mb: { xs: 0, md: 6 } }}>
                    <Typography variant="bodySmall">
                      <Link onClick={toggleLoginType} underline="none" sx={{ mx: '5px', cursor: 'pointer' }}>
                        <Typography variant={isEnglish ? 'bodyMediumB' : 'cairoBold'} component="span">
                          {t(loginType === 'mobile' ? 'loginWithEmail' : 'loginWithMobile')}
                        </Typography>
                      </Link>
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="bodySmall">
                    {t('noAccount')}
                    <Link component={ReactLink} to="/sign-up" underline="none" sx={{ mx: '5px' }}>
                      <Typography variant={isEnglish ? 'bodyMediumB' : 'cairoBold'} component="span">
                        {t('signUp')}
                      </Typography>
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CenterContent> */}
    </Box>
  );
};

export default Login;
