import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AddRecurrentBillingForm from '../components/User/MyWallet/AddRecurrentBillingForm';
import { ReduxState } from '../redux/store';

interface PaymentHyperPayProcessingScreenProps {
  checkoutId?: string;
  isRecurring: boolean;
  onCloseRecurringSettings?: () => void;
}

const PaymentHyperPayProcessingScreen = ({
  checkoutId,
  isRecurring,
  onCloseRecurringSettings,
}: PaymentHyperPayProcessingScreenProps) => {
  const { t, i18n } = useTranslation();
  const componentRef = React.useRef<any>();
  const [isSubscriptionSubmitted, setIsSubscriptionSubmitted] = React.useState(false);
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

  const { backendProductId, toScreen, brandType } = useSelector(
    (storeData: ReduxState) => storeData.selectedCardToInvest,
  );

  console.log({ backendProductId, toScreen, brandType });

  // const location = useLocation();
  // console.log('location', location.pathname, backendProductId);

  const injectCustomStyles = () => {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      .muiltr-37oppt { 
        padding: 0 !important;
      }
      .muirtl-37oppt{
        padding: 0 !important;
      }
      .wpwl-form-card{
        margin:0% !important;
        border-radius: 0% !important
      }
    `;
    document.head.appendChild(style);
  };
  const loadHyperPayCheckoutScript = () => {
    if (checkoutId) {
      const currentLanguage = i18n.language === 'ar' ? 'ar-SA' : 'en';
      const styleScript = document.createElement('script');
      styleScript.async = true;
      styleScript.type = 'text/javascript';
      styleScript.innerHTML = `
        var wpwlOptions = {
          onChangeBrand: function(data){sessionStorage.setItem('hyperPayBrand', data)},
          style:"card",numberFormatting:false,
          locale: "${currentLanguage}"
        }`;

      document.body.appendChild(styleScript);

      const script = document.createElement('script');

      script.id = 'hyper-pay-checkout';
      if (isProduction) {
        script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      } else {
        script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      }
      script.async = true;

      componentRef.current?.appendChild(script);

      const form = document.createElement('form');
      // form.action = `http://localhost:3000/${toScreen}?backendProductId=${backendProductId}&brandType=${brandType}`;
      form.action = `${window.location.protocol}//${window.location.hostname}/${toScreen}?backendProductId=${backendProductId}&brandType=${brandType}`;

      form.setAttribute('class', 'paymentWidgets');
      form.setAttribute('data-brands', isProduction ? 'MADA' : 'MADA VISA MASTER');
      componentRef.current?.appendChild(form);
    }
    injectCustomStyles();
  };

  const unloadHyperPayCheckoutScript = () => {
    if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
      window.wpwl.unload();
      document.querySelectorAll('script#hyper-pay-checkout').forEach((script) => script.remove());
    }
  };

  React.useEffect(() => {
    loadHyperPayCheckoutScript();
    return () => {
      unloadHyperPayCheckoutScript();
    };
  }, [checkoutId, isSubscriptionSubmitted, brandType, backendProductId]);

  if (!isRecurring || isSubscriptionSubmitted) {
    return <Box className="div-box" ref={componentRef} />;
  }

  return (
    <AddRecurrentBillingForm
      onCloseRecurringSettings={onCloseRecurringSettings}
      onSubscriptionSubmit={setIsSubscriptionSubmitted}
    />
  );
};

export default PaymentHyperPayProcessingScreen;
