import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import debounce from 'lodash.debounce';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTypedDispatch } from '../../../redux/store';
import { clearTransactions, findTransactionsHistory } from '../../../store_deprecated/actions/transactionActions';
import { getTransactionsHistorySelector } from '../../../store_deprecated/selectors/transactionSelectors';
import { TransactionActivityEnum, TransactionsHistoryDataItem } from '../../../store_deprecated/types/transactionTypes';
import COLORS from '../../../constants/colors';
import Filters from './Filters';
import MButton from '../../../shared/MButton';
import { formatNumber } from '../../../utils/number';
import { findUserIndexFunds } from '../../../store_deprecated/actions/indexFundActions';
import { getUserIndexFundsSelector } from '../../../store_deprecated/selectors/indexFundSelectors';
import { DATE_FORMAT } from '../../../utils/dates';

const TransactionsHistoryView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useTypedDispatch();
  const [filter, setFilter] = useState('');
  const isEnglish = i18n.language === 'en';

  const transactionsHistory = useSelector(getTransactionsHistorySelector);
  const userIndexFunds = useSelector(getUserIndexFundsSelector);

  const formatUnit = (unit: string) => (i18n.exists(`unit.${unit}`) ? t(`unit.${unit}`) : unit);

  const downloadStatement = () => {
    const csvType = 'data:text/csv;charset=utf-8,';
    const universalBOM = '\uFEFF';
    let csvContent = '';
    //* ****** Heading *******/
    const heading = isEnglish ? 'Activity Type,Amount,Date,Description,' : 'نوع النشاط,المبلغ,التاريخ,وصف,';

    csvContent += heading.replace(/(^\[)|(\]$)/gm, '');

    transactionsHistory.data.items.forEach((item) => {
      const activityKey =
        Object.keys(TransactionActivityEnum)[Object.values(TransactionActivityEnum).indexOf(item.activity.activity)];
      const amount = formatNumber(Number(item.amount) || 0, i18n.language, 2);
      const formattedAmount = amount ? `"${amount}"` : 0;
      const row = `${t(`transactionsHistory.csvReport.${activityKey}`)},${formattedAmount} ${formatUnit(
        'SAR',
      )},${moment(item.createdAt).locale(i18n.language).format('MMM D YYYY')},${
        isEnglish ? item.activity.descriptionEn : item.activity.descriptionAr
      },`;

      csvContent = [csvContent, row].join('\n').replace(/(^\[)|(\]$)/gm, '');
    });

    const data = encodeURIComponent(universalBOM + csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', csvType + data);
    link.setAttribute('download', `${isEnglish ? 'Transactions' : 'سجل العمليات'}.csv`);
    link.click();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTransactions = useCallback(
    debounce((newFilter: string, limit = 10, offset = 0) => {
      dispatch(findTransactionsHistory({ filter: newFilter, limit, offset }));
    }, 300),
    [],
  );

  useEffect(() => {
    searchTransactions('');
    dispatch(findUserIndexFunds());

    return () => {
      dispatch(clearTransactions());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderHistoryItem = (item: TransactionsHistoryDataItem) => {
    const activityKey =
      Object.keys(TransactionActivityEnum)[Object.values(TransactionActivityEnum).indexOf(item.activity.activity)];
    const userIndexFund = userIndexFunds.find((uif) => uif.indexFund.id === item.details.indexFundId)!;
    const strategy = isEnglish
      ? userIndexFund?.indexFund?.assetClass?.nameEn
      : userIndexFund?.indexFund?.assetClass?.nameAr;

    return (
      <Grid item xs={12} key={item.id}>
        <Grid
          container
          rowSpacing="0px"
          flexDirection="column"
          component={Paper}
          elevation={0}
          sx={{ p: '24px', borderRadius: '12px' }}
        >
          <Grid item display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                fontSize: { xs: '16px', md: '20px' },
                lineHeight: '28px',
                color: COLORS.DARK_GREY,
              }}
            >
              {t(`transactionsHistory.activity.${activityKey}`, { strategy })}
            </Typography>
            <Typography
              variant="bodySmall"
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '20px',
                color: COLORS.LIGHT_GRAY,
                display: { xs: 'none', sm: 'block' },
              }}
            >
              {moment(item.createdAt).locale(i18n.language).format(DATE_FORMAT)}
            </Typography>
          </Grid>
          <Typography
            variant="bodySmall"
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '20px',
              color: COLORS.LIGHT_GRAY,
              display: { xs: 'block', sm: 'none' },
            }}
          >
            {moment(item.createdAt).locale(i18n.language).format(DATE_FORMAT)}
          </Typography>
          <Grid
            item
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ my: '16px' }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 500,
                fontSize: { xs: '18px', md: '28px' },
                lineHeight: '36px',
                color: COLORS.MAIN_DARK,
              }}
            >
              {formatNumber(Number(item.amount) || 0, i18n.language, 2)}
              &nbsp;{formatUnit('SAR')}
            </Typography>
          </Grid>
          <Grid item display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="bodySmall"
              sx={{
                fontWeight: 400,
                fontSize: { xs: '12px', md: '14px' },
                lineHeight: '24px',
                color: COLORS.LIGHT_GRAY,
              }}
            >
              {isEnglish ? item.activity.descriptionEn : item.activity.descriptionAr}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const onSearch = (newFilter: string) => {
    setFilter(newFilter);
    searchTransactions(newFilter);
  };

  const loadMore = () => {
    searchTransactions(filter, 10, transactionsHistory.data.items.length);
  };

  const renderLoader = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      }}
    >
      <CircularProgress />
    </Box>
  );

  return (
    <Box
      sx={{
        padding: { xs: '56px 24px', md: '32px 32px 0 32px' },
        maxHeight: { xs: '100vh', md: 'calc(100vh - 275px)' },
        '@media (max-width: 1000px) and  (orientation: landscape)': {
          maxHeight: '400px',
          overflowY: 'auto',
        },
        mt: { xs: '-56px', md: 'auto' },
      }}
    >
      <Grid container flexDirection="column" justifyContent="space-between" position="relative">
        <Grid item container flexDirection="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography component="h1" variant="h5" sx={{ fontWeight: 500, fontSize: '24px', lineHeight: '32px' }}>
              {t('transactionsHistory.title')}
            </Typography>
          </Grid>
          <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
            <MButton
              variant="outlined"
              onClick={downloadStatement}
              id="DownloadStatementButton"
              fullWidth
              buttonProps={{
                sx: {
                  height: { xs: '50px', md: 60 },
                  borderRadius: '80px',
                  boxShadow: 'none',
                  border: '2px solid #0061A7',
                },
              }}
              typographyProps={{
                sx: {
                  backgroundColor: COLORS?.X_DARK_BLUE,
                  color: COLORS?.WHITE,
                },
              }}
            >
              <LogoutIcon sx={{ transform: 'rotate(90deg)', mr: '12px' }} />
              {t('transactionsHistory.downloadStatement')}
            </MButton>
          </Grid>
        </Grid>
        <Grid item sx={{ mt: '32px' }}>
          <Filters onSearch={onSearch} />
        </Grid>
        {transactionsHistory.isLoading && renderLoader()}
        <Grid
          item
          container
          rowSpacing="18px"
          justifyContent="space-between"
          sx={{
            p: '3px',
            mt: { xs: '20px', md: '14px' },
            height: '100%',
            maxHeight: {
              xs: '30vh',
              md: 'calc(100vh - 370px - 79px - 32px - 54px)',
            },
            minHeight: {
              xs: '30vh',
              md: 'calc(100vh - 370px - 79px - 32px - 54px)',
            },
            '@media (max-width: 1000px) and  (orientation: landscape)': {
              minHeight: '300px',
            },
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '5px !important',
              backgroundColor: 'transparent !important',
              borderRadius: '4px !important',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#111827 !important',
              borderRadius: '4px !important',
            },
          }}
        >
          {transactionsHistory.data.items.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', color: COLORS.LIGHT_GRAY }}
            >
              <Typography variant={isEnglish ? 'smallDisplayH' : 'cairoHeader1B'} gutterBottom>
                {t('noItems')}
              </Typography>
            </Box>
          )}
          {transactionsHistory.data.items.map(renderHistoryItem)}
          {transactionsHistory.data.items.length < transactionsHistory.data.totalItems && (
            <Grid item width="100%" display="flex" justifyContent="center">
              <MButton
                variant="contained"
                onClick={loadMore}
                disabled={transactionsHistory.isLoading}
                buttonProps={{
                  sx: {
                    padding: '8px 24px',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    backgroundColor: COLORS?.X_DARK_BLUE,
                  },
                }}
                typographyProps={{
                  variant: !isEnglish ? 'cairoBodyB' : 'bodySmallM',
                }}
                id="SearchButton"
              >
                {t('transactionsHistory.loadMore')}
              </MButton>
            </Grid>
          )}
        </Grid>
        <Grid item sx={{ display: { xs: 'block', md: 'none' }, mt: '30px' }}>
          <MButton
            variant="outlined"
            onClick={downloadStatement}
            id="DownloadStatementButton"
            fullWidth
            buttonProps={{
              sx: {
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
                backgroundColor: COLORS?.X_DARK_BLUE,
              },
            }}
            typographyProps={{
              sx: {
                padding: { xs: '16px', md: '16px 48px' },
              },
            }}
          >
            <LogoutIcon sx={{ transform: 'rotate(90deg)', mr: '12px' }} />
            {t('transactionsHistory.downloadStatement')}
          </MButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionsHistoryView;
