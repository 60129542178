import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import COLORS from '../../../constants/colors';
import MButton from '../../../shared/MButton';

export type FilterItem = {
  id?: number | string;
  columnField: string;
  value?: any;
  operatorValue?: string;
};

export type Filter = {
  items: FilterItem[];
};

interface FiltersProps {
  onSearch: (filter: string) => void;
}

const Filters: React.FC<FiltersProps> = ({ onSearch }) => {
  const { t, i18n } = useTranslation();
  const [filterFrom, setFilterFrom] = useState<Date | null>(null);
  const [filterTo, setFilterTo] = useState<Date | null>(null);
  const isArabic = i18n.language === 'ar';
  const matchMobileView = useMediaQuery('(max-width:600px)');

  const datePickerTextFieldSlotProps = {
    style: { padding: '13px', borderRadius: '8px' },
    required: true,
    fullWidth: true,
    sx: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: COLORS.XLIGHT_GRAY,
      borderRadius: '8px',
      height: '100%',
      '&:hover': { borderColor: 'black' },
      '& input': { padding: '0' },
      '& fieldset': { padding: '0', border: 'none' },
    },
    placeholder: isArabic ? 'يوم/شهر/سنة' : 'dd/mm/yyyy',
  };

  const resetFilter = () => {
    setFilterFrom(null);
    setFilterTo(null);

    onSearch(JSON.stringify({ items: [] }));
  };

  const onChangeFilterFrom = (value: Date | null) => {
    setFilterFrom(value);

    if (value && filterTo && +new Date(value) > +new Date(filterTo)) {
      setFilterTo(value);
    }
  };

  const onChangeFilterTo = (value: Date | null) => {
    setFilterTo(value);

    if (value && filterFrom && +new Date(value || '') < +new Date(filterFrom)) {
      setFilterFrom(value);
    }
  };

  const prepareFilter = () => {
    const filter: Filter = {
      items: [],
    };

    const adjustTimezoneOffset = (date: any) => {
      const offsetInMs = date.getTimezoneOffset() * 60000;
      return new Date(date.getTime() - offsetInMs);
    };

    const setToLastMinuteOfDay = (date: any) => {
      const adjustedDate = new Date(date);
      adjustedDate.setHours(23, 59, 59, 999);
      return adjustedDate;
    };

    if ([filterFrom, filterTo].filter(Boolean).length === 2) {
      filter.items.push({
        columnField: 'createdAt',
        value: [
          adjustTimezoneOffset(filterFrom)?.toISOString(),
          setToLastMinuteOfDay(adjustTimezoneOffset(filterTo)?.toISOString()),
        ],
        operatorValue: 'between',
      });

      return filter;
    }

    if (filterFrom) {
      filter.items.push({
        columnField: 'createdAt',
        value: adjustTimezoneOffset(filterFrom).toISOString(),
        operatorValue: 'onOrAfter',
      });
    }

    if (filterTo) {
      filter.items.push({
        columnField: 'createdAt',
        value: adjustTimezoneOffset(filterTo).toISOString(),
        operatorValue: 'onOrBefore',
      });
    }

    return filter;
  };

  const search = () => {
    onSearch(JSON.stringify(prepareFilter()));
  };

  return (
    <Grid container display="flex" flexDirection="row" justifyContent="center" alignItems="center" columnSpacing="24px">
      <Grid item xs={12} md={4}>
        <FormControl sx={{ width: '100%' }} variant="outlined">
          <Typography
            variant={isArabic ? 'cairoM' : 'bodyMedium'}
            color={COLORS.MAIN_DARK}
            textAlign="justify"
            paragraph
            sx={{ marginBottom: 1 }}
          >
            {t('transactionsHistory.from')}
          </Typography>
          <DatePicker
            value={filterFrom}
            maxDate={filterTo}
            format="dd,MM,yyyy"
            onChange={(date) => onChangeFilterFrom(date)}
            slotProps={{
              textField: {
                autoFocus: true,
                id: 'dateFilterFrom',
                name: 'dateFilterFrom',
                ...datePickerTextFieldSlotProps,
              },
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl sx={{ width: '100%' }} variant="outlined">
          <Typography
            variant={isArabic ? 'cairoM' : 'bodyMedium'}
            color={COLORS.MAIN_DARK}
            textAlign="justify"
            paragraph
            sx={{ marginBottom: 1 }}
          >
            {t('transactionsHistory.to')}
          </Typography>
          <DatePicker
            value={filterTo}
            format="dd,MM,yyyy"
            minDate={filterFrom}
            onChange={(date) => onChangeFilterTo(date)}
            slotProps={{
              textField: {
                id: 'dateFilterTo',
                name: 'dateFilterTo',
                ...datePickerTextFieldSlotProps,
              },
            }}
          />
        </FormControl>
      </Grid>
      <Grid item container flexDirection="column" xs={12} md={4}>
        <Grid item>
          <Typography sx={{ height: '24px' }} />
        </Grid>
        <Grid
          item
          container
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent={matchMobileView ? 'center' : 'flex-end'}
          columnSpacing="24px"
        >
          <Grid item>
            <MButton
              variant="contained"
              onClick={search}
              id="SearchButton"
              buttonProps={{
                sx: {
                  padding: '8px 24px',
                  height: { xs: '50px', md: 60 },
                  backgroundColor: COLORS?.X_DARK_BLUE,
                  '&:hover': {
                    backgroundColor: COLORS?.X_DARK_BLUE,
                  },
                },
              }}
              typographyProps={{
                variant: isArabic ? 'cairoBodyB' : 'bodySmallM',
              }}
            >
              {t('transactionsHistory.search')}
            </MButton>
          </Grid>
          <Grid item sx={{ mr: '14px' }}>
            <MButton
              variant="text"
              onClick={resetFilter}
              id="BackButton"
              buttonProps={{
                sx: {
                  padding: '8px 24px',
                  backgroundColor: COLORS.X_LIGHT_CYAN,
                  height: { xs: '50px', md: 60 },
                },
              }}
              typographyProps={{
                variant: isArabic ? 'cairoBodyB' : 'bodySmallM',
              }}
            >
              {t('transactionsHistory.reset')}
            </MButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filters;
