import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import MButton from '../../../shared/MButton';
import { locations } from '../../../routes/locations';
import { useTypedDispatch } from '../../../redux/store';
import { getPortfolioStatisticsSelector } from '../../../store_deprecated/selectors/portfolioSelectors';
import { StyledTab, StyledTabs, TabPanel } from '../../../shared/CustomTabs';
import PortfolioPositionsTab from './PortfolioPositionsTab';
import TargetAllocationTab from './TargetAllocationTab';
import Widgets from '../BetaDashboard/Widgets';
import AddMoreFundDialog from '../../../shared/AddMoreFundDialog';
import { fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import {
  findPortfolioStatistics,
  resetSubscribedToPortfolio,
} from '../../../store_deprecated/actions/portfolioActions';
import { DepositProps } from '../MyWallet/Deposit';
import { fetchAnbMasterardConfigAction } from '../../../store_deprecated/actions/walletActions';
import CompoundingReturnTab from '../PortfolioDetails/CompoundingReturnTab';
import { setProductToInvest } from '../../../redux/features/products/selectedCardToInvest';
import SuccessAndFailurePaymentModals from '../../../shared/SuccessAndFailurePaymentModals';

const Loader = React.lazy(() => import('../../../shared/Loader'));

type ModeratePortfolioProps = {
  subscribedProduct?: any;
};

const ModeratePortfolio: React.FC<ModeratePortfolioProps> = (props) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(0);
  const [showSuccessfulScreen, setShowSuccessfulScreen] = useState(false);
  const [openAddMoreFundDialog, setOpenAddMoreFundDialog] = React.useState(false);
  const [depositConfig, setDepositConfig] = useState<DepositProps | null>(null);

  const subscribedPortfolio = props?.subscribedProduct;

  const { latest: data, isLoading } = useSelector(getPortfolioStatisticsSelector);
  const userIndexFunds = subscribedPortfolio.subscribedPortfolioAssociatedIndexFund;
  const minAmount = subscribedPortfolio?.productType?.minimumAddFundInvestment;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(setProductToInvest({ backendProductId: props.subscribedProduct?.id, toScreen: 'dashboard' }));

    const fetchInitialData = async () => {
      try {
        await Promise.all([dispatch(findPortfolioStatistics()), dispatch(fetchLastSurveyResult())]);
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const anbMastercardConfig = await dispatch(fetchAnbMasterardConfigAction());

        if (anbMastercardConfig?.url && anbMastercardConfig?.version) {
          setDepositConfig({
            anbMastercardCheckoutBaseUrl: anbMastercardConfig.url,
            anbMastercardCheckoutVersion: anbMastercardConfig.version,
            // openCongratulationModal,
          });
        }
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionHandler = () => {
    if (subscribedPortfolio?.id) {
      setShowSuccessfulScreen(false);
      setOpenAddMoreFundDialog(true);
    }
  };

  const redeemHandler = () => {
    if (subscribedPortfolio?.id) {
      navigate(locations.redemption(), {
        state: {
          from: pathname,
          subscribedPortfolio,
        },
      });
    }
  };

  const closeDialog = (success?: boolean) => {
    if (success) {
      setShowSuccessfulScreen(true);
    } else {
      setOpenAddMoreFundDialog(false);
    }
  };

  const onSuccessButtonClick = () => {
    closeDialog();
    window.location.reload();
  };

  const renderActionButton = () => (
    <MButton
      variant="outlined"
      onClick={actionHandler}
      id="AddMoreFundButton"
      typographyProps={{ sx: { padding: '8px 24px' } }}
      buttonProps={{
        sx: {
          width: { xs: '100%', md: '100%' },
          height: { xs: '50px', md: 60 },
          borderRadius: '80px',
        },
      }}
    >
      <AddSharpIcon sx={{ mr: '8px' }} />
      {t('portfolioDetails.addMoreFund')}
    </MButton>
  );

  const renderRedeemButton = () => (
    <MButton
      variant="contained"
      onClick={redeemHandler}
      id="RedeemButton"
      buttonProps={{
        sx: {
          padding: '8px 24px',
          width: { xs: '100%', md: '100%' },
          height: { xs: '50px', md: 60 },
          borderRadius: '80px',
          boxShadow: 'none',
        },
      }}
    >
      {t('portfolioDetails.redeem')}
    </MButton>
  );

  return (
    <Container
      // component="main"
      sx={{
        maxWidth: '100%!important',
        padding: { xs: '0px!important', md: '32px!important' },
        mt: { xs: '-56px', md: 'auto' },
      }}
    >
      <Grid container columnSpacing={2} rowSpacing="34px" flexDirection="column">
        <Grid item>
          <Widgets subscribedProducts={[subscribedPortfolio]} />
        </Grid>
        <Grid item container flexDirection="row" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid item sx={{ width: '100%' }}>
            <Typography variant="h5" sx={{ fontWeight: 500, fontSize: '24px', lineHeight: '32px' }}>
              {isArabic ? subscribedPortfolio.nameAr : subscribedPortfolio.nameEn}
            </Typography>
          </Grid>
          <Grid item container columnSpacing={6} justifyContent="flex-end" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Grid item>{renderActionButton()}</Grid>
            <Grid item>{renderRedeemButton()}</Grid>
          </Grid>
        </Grid>
      </Grid>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ width: '100%', mt: 12 }}>
          <Box>
            <StyledTabs
              orientation="horizontal"
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <StyledTab label={t('portfolioDetails.portfolioPositionsTabLabel')} key={0} />
              <StyledTab label={t('portfolioDetails.targetAllocationTabLabel')} key={1} />
              <StyledTab label={t('portfolioDetails.сompoundingReturnsTabLabel')} key={2} />
            </StyledTabs>
          </Box>
          <TabPanel value={value} index={0}>
            <PortfolioPositionsTab
              portfolio={subscribedPortfolio}
              latestStatistics={data}
              userIndexFunds={userIndexFunds}
              cashAllocationPercentage={subscribedPortfolio?.cacheAllocationPercentage}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TargetAllocationTab
              portfolio={subscribedPortfolio}
              cashAllocationPercentage={subscribedPortfolio?.cacheAllocationPercentage}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CompoundingReturnTab
              portfolio={{
                ...subscribedPortfolio,
                associatedIndexFunds: subscribedPortfolio.subscribedPortfolioAssociatedIndexFund,
              }}
              latestStatistics={data}
              userIndexFunds={userIndexFunds}
            />
          </TabPanel>
        </Box>
      )}
      <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
        {renderActionButton()}
      </Grid>
      <Grid item sx={{ display: { xs: 'block', md: 'none' }, mt: 2 }}>
        {renderRedeemButton()}
      </Grid>
      <AddMoreFundDialog
        open={openAddMoreFundDialog}
        onSuccessButtonClick={onSuccessButtonClick}
        showSuccessfulScreen={showSuccessfulScreen}
        closeDialog={closeDialog}
        subscribedPortfolio={subscribedPortfolio}
        minAmount={minAmount}
      />
    </Container>
  );
};

export default ModeratePortfolio;
