import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import { ReduxState, useTypedDispatch } from '../redux/store';
import {
  queryHyperPayPaymentStatusAction,
  queryHyperPayRegistrationStatusAction,
  saveSubscription,
} from '../store_deprecated/actions/walletActions';

import PaymentSuccessfulScreen from './PaymentSuccessfulScreen';
import PaymentFailureScreen from './PaymentFailureScreen';
import { RecurringStatus } from '../forms/fields/recurringPayment';

type HyperPayPaymentStatus = {
  result: {
    code: string;
    description: string;
  };
  buildNumber: string;
  timestamp: string;
  ndc: string;
};

type HyperPayRegestrationStatus = {
  id: string;
  result: {
    code: string;
    description: string;
  };
  card: {
    last4Digits: string;
  };
  buildNumber: string;
  timestamp: string;
  ndc: string;
};

const SuccessAndFailurePaymentModals: React.FC = () => {
  const dispatch = useTypedDispatch();
  const [successfulDialog, setOpenSuccessfulDialog] = React.useState(false);
  const [failureDialog, setFailureDialog] = React.useState(false);
  const [last4Digits, setLast4Digits] = React.useState('');

  const shouldCallGetPaymentStatus = useRef<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get('id');
  const resourcePath = searchParams.get('resourcePath');
  const backendProductId = searchParams.get('backendProductId');
  const brandType = searchParams.get('brandType');

  console.log('SuccessAndFailurePaymentModals', backendProductId, brandType);

  const successPattern = /^(000\.000\.|000\.100\.1|000\.[36]|000\.400\.[1][12]0|000\.400\.0[^3]|000\.400\.100)/;
  const pendingPattern = /^(000\.200|800\.400\.5|100\.400\.500)/;

  // TODO: Temporary solution to pass variables between screens, need to use state
  const hyperPayBrand = brandType ?? (sessionStorage.getItem('hyperPayBrand') || '');
  const hyperPayPaymentType = sessionStorage.getItem('hyperPayPaymentType') || '';
  const subscriptionId = Number(sessionStorage.getItem('subscriptionId')) || 0;

  console.log({ hyperPayBrand, hyperPayPaymentType, subscriptionId });

  const closeDialog = () => {
    window.history.pushState({}, '', window.location.pathname);
    setOpenSuccessfulDialog(false);
    setFailureDialog(false);
    window.location.reload();
  };

  const handleHyperPayPaymentStatus = async (paymentType: 'DB' | 'PA') => {
    try {
      if (resourcePath) {
        shouldCallGetPaymentStatus.current = false;
        const paymentStatus = (await dispatch(
          queryHyperPayPaymentStatusAction({
            resourcePath,
            paymentType,
            brand: hyperPayBrand,
            subscriptionId,
            productId: backendProductId as string,
          }),
        )) as HyperPayPaymentStatus;

        const match1 = successPattern.test(paymentStatus.result.code);
        const match2 = pendingPattern.test(paymentStatus.result.code);

        return match1 || match2;
      }
      return false;
    } catch (err: any) {
      return false;
    } finally {
      shouldCallGetPaymentStatus.current = true;
    }
  };

  const handleHyperPayAddCardStatus = async () => {
    try {
      if (resourcePath) {
        shouldCallGetPaymentStatus.current = false;
        const paymentStatus = (await dispatch(
          queryHyperPayPaymentStatusAction({
            resourcePath,
            paymentType: 'RG',
            brand: hyperPayBrand,
            subscriptionId,
            productId: backendProductId as string,
          }),
        )) as HyperPayRegestrationStatus;

        const match1 = successPattern.test(paymentStatus.result.code);
        const match2 = pendingPattern.test(paymentStatus.result.code);

        const result = match1 || match2;

        if (result && paymentStatus?.card?.last4Digits) {
          setLast4Digits(paymentStatus.card.last4Digits);

          await dispatch(
            saveSubscription({
              productId: backendProductId as string,
              id: subscriptionId,
              status: RecurringStatus.REGISTERED,
            }),
          );
        }

        return match1 || match2;
      }
      return false;
    } catch (err: any) {
      return false;
    } finally {
      shouldCallGetPaymentStatus.current = true;
    }
  };

  const handleHyperPayRegestrationStatus = async () => {
    try {
      if (resourcePath) {
        const paymentStatus = (await dispatch(
          queryHyperPayRegistrationStatusAction({ resourcePath, paymentType: 'RG', brand: hyperPayBrand }),
        )) as HyperPayRegestrationStatus;
        const match1 = successPattern.test(paymentStatus.result.code);
        const match2 = pendingPattern.test(paymentStatus.result.code);
        const result = match1 || match2;
        if (result) {
          setLast4Digits(paymentStatus.card.last4Digits);
        }
        return result;
      }
      return false;
    } catch (err: any) {
      return false;
    }
  };

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      const isStandalone = hyperPayPaymentType === 'RG';
      const isRecurring = hyperPayPaymentType === 'PA';
      // const isSuccess = isStandalone
      //   ? await handleHyperPayRegestrationStatus()
      //   : await handleHyperPayPaymentStatus(isRecurring ? 'PA' : 'DB');

      let type = 'DB';

      if (isRecurring) {
        type = 'PA';
      }

      const isSuccess = isStandalone
        ? await handleHyperPayAddCardStatus()
        : await handleHyperPayPaymentStatus(type as any);

      if (isSuccess && orderId) {
        setOpenSuccessfulDialog(true);
      } else {
        setFailureDialog(true);
      }
    };
    if (resourcePath && shouldCallGetPaymentStatus.current) {
      fetchPaymentStatus();
    }
  }, [orderId, resourcePath]);

  return (
    <Box
      sx={{
        padding: { xs: '0 24px', md: '32px' },
        mt: { xs: '0', md: 'auto' },
        minHeight: successfulDialog || failureDialog ? '70vh' : 'auto',
      }}
    >
      <Dialog open={successfulDialog} onClose={closeDialog} disableScrollLock>
        <Box sx={{ padding: '32px 24px' }}>
          <PaymentSuccessfulScreen
            onSuccessButtonClick={closeDialog}
            orderId={orderId || ''}
            resourcePath={resourcePath || ''}
            last4Digits={last4Digits || ''}
          />
        </Box>
      </Dialog>
      <Dialog open={failureDialog} onClose={closeDialog} disableScrollLock>
        <Box sx={{ padding: '32px 24px' }}>
          <PaymentFailureScreen
            onSuccessButtonClick={closeDialog}
            orderId={orderId || ''}
            resourcePath={resourcePath || ''}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default SuccessAndFailurePaymentModals;
