import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import COLORS from '../constants/colors';
import MButton from './MButton';
import FundAmountField from '../components/User/Education/FundAmountField';
import { formatNumber, round2Format } from '../utils/number';
import { formatValue } from '../helpers/string';
import { madkhoulBankInfoSelector } from '../store_deprecated/selectors/walletSelectors';
import { getAddMoreFundToPortfolioSelector } from '../store_deprecated/selectors/portfolioSelectors';
import {
  addMoreFundToPortfolio,
  requestAddMoreFundToPortfolioReset,
  resetSubscribedToPortfolio,
} from '../store_deprecated/actions/portfolioActions';
import { ReduxState, useTypedDispatch } from '../redux/store';
import { SubscribedPortfolio } from '../store_deprecated/types/portfolioTypes';
import SelectInput from './SelectInput';
import { setProductToInvest } from '../redux/features/products/selectedCardToInvest';

interface AddFundFromWalletProps {
  handleClose: () => void;
  amount: string;
  handleAmountField: (event: any) => void;
  minAmount: number;
  subscribedPortfolio?: SubscribedPortfolio;
  closeDialog: (success?: boolean) => void;
  PaymentMethodChild: JSX.Element;
  shouldShowProductList?: boolean;
}

export default function AddFundFromWallet({
  handleClose,
  amount,
  handleAmountField,
  minAmount,
  subscribedPortfolio,
  closeDialog,
  PaymentMethodChild,
  shouldShowProductList,
}: AddFundFromWalletProps) {
  const bankItem = useSelector(madkhoulBankInfoSelector);

  const { t: tApiError } = useTranslation('apiError');

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const isFundAmountError = Boolean(amount) && +amount < minAmount;

  const addMoreFundToPortfolioData = useSelector(getAddMoreFundToPortfolioSelector);

  const error = tApiError(addMoreFundToPortfolioData.error);
  const { isLoading } = addMoreFundToPortfolioData;
  const dispatch = useTypedDispatch();

  const { backendProductId } = useSelector((state: ReduxState) => state.selectedCardToInvest);

  const products: any = useSelector((storeData: ReduxState) => storeData.products.products);

  const onConfirm = async () => {
    const _amount = +amount.replace(/\D/g, '');
    if (amount && subscribedPortfolio) {
      const result = (await dispatch(
        addMoreFundToPortfolio({
          portfolioId: subscribedPortfolio.id,
          fundAmount: _amount,
          productId: backendProductId ?? '',
        }),
      )) as any;

      if (result) {
        closeDialog(true);
      }
    } else {
      closeDialog(false);
      dispatch(resetSubscribedToPortfolio());
    }
  };

  const handleChange = (param: any) => {
    const product = products.find((p: any) => p.backendProductId === param.target.value);

    dispatch(
      setProductToInvest({
        backendProductId: param.target.value,
        toScreen: 'my-wallet',
        isSubscribed: product?.isSubscribed,
        product,
      }),
    );
  };

  return (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" rowSpacing="48px" padding="0px">
      <Grid item textAlign="right" alignSelf="end">
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ fontSize: '12px', color: COLORS.MAIN_DARK }} />
        </IconButton>
      </Grid>
      <Grid
        item
        sx={{
          paddingTop: { xs: '24px !important', md: '48px !important' },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: 500,
            fontSize: { xs: '24px', md: '44px' },
            lineHeight: { xs: '32px', md: '48px' },
            color: COLORS.MAIN_DARK,
          }}
        >
          {t('addMoreFundDialog.title')}
        </Typography>
      </Grid>
      <Grid item container flexDirection="row" justifyContent="space-around" alignItems="center">
        <Typography
          variant="bodyMedium"
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('addMoreFundDialog.currentFund')}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '28px',
            color: COLORS.PRIMARY_BLUE,
          }}
        >
          {t('addMoreFundDialog.nPerMonth', {
            val: round2Format(Number(bankItem?.userBankDetails?.amount) || 0, i18n.language, true),
          })}
        </Typography>
      </Grid>
      {shouldShowProductList && (
        <Grid item container xs={12} sx={{ marginTop: 8 }}>
          <SelectInput
            id="productList"
            name="products"
            label="depositDialog.productListTitle"
            selectPlaceholder="depositDialog.select"
            values={products?.map((product: any) => ({
              label: isArabic ? product?.productType?.nameAr : product?.productType?.nameEn,
              value: product?.backendProductId,
            }))}
            handleChange={handleChange}
            required
            value={backendProductId}
          />
        </Grid>
      )}
      <Grid item sx={{ width: '100%' }}>
        <FundAmountField
          isArabic={isArabic}
          value={formatValue(isArabic, amount.toString())}
          handleChange={(event) => {
            dispatch(requestAddMoreFundToPortfolioReset());
            handleAmountField(event);
          }}
        />
        <Typography
          variant="bodySmall"
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('addMoreFundDialog.note', { val: formatNumber(minAmount, i18n.language) })}
        </Typography>
      </Grid>
      {(isLoading || !!error || isFundAmountError) && (
        <Grid item>
          {isLoading && <CircularProgress />}
          {(!!error || isFundAmountError) && (
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '28px',
                color: COLORS.RED,
              }}
            >
              {error ||
                tApiError('FUND_AMOUNT_LESS_THAN_MINIMUM_SUBSCRIPTION_AMOUNT', {
                  val: formatNumber(minAmount, i18n.language, 2),
                })}
            </Typography>
          )}
        </Grid>
      )}
      {PaymentMethodChild}
      <Grid item container rowSpacing="24px">
        <Grid item width="100%">
          <MButton
            id="ConfirmAddMoreFundButton"
            variant="contained"
            onClick={onConfirm}
            disabled={!amount || isFundAmountError || isLoading}
            fullWidth
            buttonProps={{
              sx: {
                backgroundColor: COLORS.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS.X_DARK_BLUE,
                },
              },
            }}
          >
            {t('addMoreFundDialog.confirmButtonTitle')}
          </MButton>
        </Grid>
        <Grid item width="100%">
          <MButton variant="outlined" onClick={handleClose} disabled={isLoading} fullWidth>
            {t('cancel')}
          </MButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
