import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { userPreferencePortfoliosSelector } from '../../redux/modules/userPreference/userPreference.selectors';
import { useFetchSubscribedProductsQuery } from '../../redux/features/products/products.apiSlice';
import { fetchRecommendedPortfolios } from '../../redux/modules/userPreference/userPreference.actions';
import { useTypedDispatch } from '../../redux/store';

function useGetUserProducts() {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchRecommendedPortfolios());
  }, []);

  const recommendedPortfolios = useSelector(userPreferencePortfoliosSelector);

  const { data: subscribedProducts } = useFetchSubscribedProductsQuery({});

  const alreadySubscribedProducts = subscribedProducts?.items?.map((p) => ({ ...p, isSubscribed: true })) ?? [];

  const allProducts = [...alreadySubscribedProducts, ...recommendedPortfolios].map((product) => {
    const backendProductId = product?.isSubscribed ? product?.id : product?.recommendedPortfolioId;

    return {
      ...product,
      backendProductId,
    };
  });

  return allProducts;
}

export default useGetUserProducts;
