import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTypedDispatch } from '../../../redux/store';
import {
  findRecommendedPortfolio,
} from '../../../store_deprecated/actions/portfolioActions';
import RiskAssessmentScore from './RiskAssessmentScore';
import { fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import { fetchUserPreferenceByType } from '../../../redux/modules/userPreference/userPreference.actions';
import { getSurveyResult } from '../../../store_deprecated/selectors/surveySelectors';
import Loader from '../../../shared/Loader';
import { UserPreferenceTypeEnum } from '../../../types/userPreference';

const InvestView: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const [initialized, setInitialized] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const surveyResult = useSelector(getSurveyResult);

  const fetchRecommendedPortfolio = useCallback((category: string) => {
    dispatch(findRecommendedPortfolio(category));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([
          dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.RECOMMENDED_PORTFOLIO)).unwrap(),
          dispatch(fetchLastSurveyResult()),
        ]);
      } catch (e) {
        console.log('Some error');
      }
      setInitialized(true);
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialized) {
      fetchRecommendedPortfolio(surveyResult.category?.nameEn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyResult, initialized]);

  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      () => {
        setInitialized(false);
        setTimeout(() => {
          setInitialized(true);
        }, 0);
      },
      false,
    );

    // eslint-disable-next-line no-restricted-globals, react-hooks/exhaustive-deps
  }, []);

  if (!initialized) {
    return <Loader />;
  }

  return (
    <Box sx={{ padding: { xs: '48px 24px', md: '32px 32px 0 32px' } }} maxWidth="100%">
      <Grid container flexDirection="column" rowSpacing="32px">
        <Grid item sx={{ paddingTop: { xs: '0 !important', md: '32px !important' } }} maxWidth="100%">
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontWeight: { xs: 600, md: 500 },
              fontSize: { xs: '14px', md: '24px' },
              lineHeight: { xs: '20px', md: '32px' },
            }}
          >
            {t('invest.title')}
          </Typography>
        </Grid>

        <Grid item maxWidth="100%">
          <RiskAssessmentScore />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvestView;
