import { ApiResponse } from './api';

export enum UserPreferenceTypeEnum {
  PERF_LANG = 'perfLang',
  RECOMMENDED_PORTFOLIO = 'recommendedPortfolio',
  PRODUCT_TYPE = 'productType',
}

export type UserPreferenceType = {
  id: number;
  type: UserPreferenceTypeEnum;
};

export type UserPreference = {
  id: number;
  preferenceValue: string;
  preferenceType: UserPreferenceType;
  createdAt: Date;
  updatedAt: Date;
};

export type FetchUserPreferenceResponse = ApiResponse<UserPreference>;

export type SaveUserPreferenceRequest = { value: string; type: UserPreferenceTypeEnum };
export type SaveUserPreferenceResponse = ApiResponse<UserPreference>;
