import React from 'react';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import MButton from '../../../shared/MButton';
import './ApplePay.css';
import COLORS from '../../../constants/colors';
import useHyperPay from '../../../helpers/hooks/useHyperPay';
import AddMoreFundDialog from '../../../shared/AddMoreFundDialog';
import MyWalletAddMoneyDialog from '../../../shared/MyWalletAddMoneyDialog';

export type DepositProps = {
  anbMastercardCheckoutBaseUrl?: string;
  anbMastercardCheckoutVersion?: string;
  anbMastercardCheckoutCancelCallBack?: string;
  page?: string;
  paddingTop?: number;
  paddingBottom?: number;
};

const Deposit: React.FC<DepositProps> = ({
  anbMastercardCheckoutBaseUrl,
  anbMastercardCheckoutVersion,
  anbMastercardCheckoutCancelCallBack = 'anbMastercardCheckoutCancelCallback',
  page,
  paddingTop = 24,
  paddingBottom = 0,
}) => {
  const { closeDialog, onDeposit, onSuccessButtonClick, openDepositDialog, showSuccessfulScreen } = useHyperPay({
    anbMastercardCheckoutBaseUrl,
    anbMastercardCheckoutCancelCallBack,
    anbMastercardCheckoutVersion,
    paddingBottom,
    paddingTop,
    page,
  });

  return (
    <>
      <Box
        display="block"
        justifyContent="center"
        alignItems="center"
        paddingTop={`${paddingTop}px`}
        paddingBottom={`${paddingBottom}px`}
      >
        <MButton
          variant="contained"
          onClick={onDeposit}
          id="DepositButton"
          // disabled={isLoading}
          buttonProps={{
            sx: {
              width: { xs: '100%', md: '100%' },
              height: { xs: '50px', md: 60 },
              borderRadius: '80px',
              boxShadow: 'none',
              p: { xs: '16px 32px', md: '16px 48px' },
              backgroundColor: COLORS?.X_DARK_BLUE,
            },
          }}
        >
          {t('deposit')}
        </MButton>
      </Box>

      <MyWalletAddMoneyDialog
        open={openDepositDialog}
        onSuccessButtonClick={onSuccessButtonClick}
        closeDialog={closeDialog}
        defaultSelectedMethod="WALLET"
        shouldShowProductList
      />
    </>
  );
};

export default Deposit;
